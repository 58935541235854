import React from "react";
import axios from "axios";
import APIURL from "../../Main/apiurl";

const create_supplier = async (supplier) => {
  try {
    let data = {
      name: supplier?.name,
      email: supplier?.email,
      phone: supplier?.phone,
      catalog: JSON?.stringify(supplier?.catalog),
      tax: supplier?.tax,
      area: supplier?.area,
      city: supplier?.city,
      state: supplier?.state,
      country: supplier?.country,
      type: supplier?.type,
      status: supplier?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/create-supplier`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_supplier = async (supplier) => {
  try {
    let data = {
      id: supplier?.id,
      name: supplier?.name,
      email: supplier?.email,
      phone: supplier?.phone,
      catalog: JSON?.stringify(supplier?.catalog),
      tax: supplier?.tax,
      area: supplier?.area,
      city: supplier?.city,
      state: supplier?.state,
      country: supplier?.country,
      type: supplier?.type,
      status: supplier?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/update-supplier`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_supplier = async (supplier) => {
  try {
    const id = supplier?.id;

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.get(`${APIURL}/get-supplier/${id}`, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_suppliers = async (supplier) => {
  try {
    const data = {
      search: supplier?.search,
      sort: supplier?.sort,
      catalog: supplier?.catalog,
      type: supplier?.type,
      status: supplier?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-suppliers`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export { create_supplier, update_supplier, get_supplier, get_all_suppliers };
