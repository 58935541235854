import { AutoComplete, DatePicker, Form, Input, Select } from "antd";
import React, { createRef, useEffect, useRef, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";
import AddButton from "../../../../Componets/Buttons/add";
import { select_id, select_search } from "../../../../Controllers/Global";
import dayjs from "dayjs";
import moment from "moment";
import taxes from "../../../taxes.json";
import payment_types from "../../../payment_types.json";
import {
  get_product,
  get_product_barcode,
} from "../../../../Controllers/Products/Products";
import Error from "../../../../Componets/Error";
import Modalcomponent from "../../../../Componets/Modal";

const Purchaseordertable = React.forwardRef((props, focus) => {
  const { details, setDetails, totals, setTotals } = props;

  focus.current[`delivery_date`] = createRef();
  focus.current[`payment_types`] = createRef();

  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const [selcectedModalData, setSelcectedModalData] = useState();

  const handleSearch = async (e, index) => {
    let data = [...details];

    const select = {
      api: "get-all-products",
      search: e,
      status: 1,
    };

    const search = await select_search(select);

    if (search?.status) {
      let options = [];
      search?.data?.data?.map((value, index) => {
        options?.push({ value: value?._id, label: value?.name });
      });

      data[index].options = options;

      setDetails(data);
    } else {
      toast?.error(search?.message);
    }
  };

  const handleModal = (modaldata) => {
    let data = [...details];

    let product = selcectedModalData;
    let index = selcectedModalData?.index;

    let product_description = null;
    let product_name = "";
    let product_unit = "";
    let product_unit_name = "";
    let product_conversion = 0;
    let product_unit_details = [];

    let description_ids = [];

    data?.map((v, i) => {
      description_ids?.push(v?.unit);
    });

    if (!description_ids?.includes(modaldata)) {
      if (product?.data?.unit?._id == modaldata) {
        product_description = product?.data?._id;
        product_name = product?.data?.name;
        product_unit = modaldata;
        product_unit_name = product?.data?.unit?.name;

        if (product?.data?.product_units_details?.length > 0) {
          product?.data?.product_units_details?.map((value, index) => {
            product_unit_details?.push({
              ...value,
              name: value?.name?.name,
              price_per_unit: 0,
              sale_price: "",
              unit_quantity: 0,
              unit_delivered: 0,
            });
          });
        }
      } else {
        let selected_unit = "";
        let unit_ids = [];

        if (product?.data?.product_units_details?.length > 0) {
          product?.data?.product_units_details?.map((value, index) => {
            unit_ids?.push(value?._id);
          });
        }

        if (unit_ids?.includes(modaldata)) {
          selected_unit =
            product?.data?.product_units_details?.[
              unit_ids?.indexOf(modaldata)
            ];
        }

        product_description = product?.data?._id;
        product_name = product?.data?.name;
        product_unit = modaldata;
        product_unit_name = selected_unit?.name?.name;
        product_conversion = selected_unit?.conversion;
      }

      data[index].description = product_description;
      data[index].name = product_name;
      data[index].unit = product_unit;
      data[index].unit_name = product_unit_name;
      data[index].quantity = 1;
      data[index].conversion = product_conversion;
      data[index].unit_details_options = product_unit_details;

      setDetails(data);
      setModalData();
      setSelcectedModalData();
    } else {
      let selected_index = description_ids?.indexOf(modaldata);
      let quantity = data[selected_index].quantity
        ? data[selected_index].quantity
        : 0;

      if (
        data?.[selected_index]?.conversion > 0 &&
        data?.[selected_index]?.quantity + parseFloat(1) >=
          data?.[selected_index]?.conversion
      ) {
        toast?.error(
          `Quantity exceeding ${data?.[selected_index]?.conversion}`
        );
      } else {
        data[selected_index].quantity = parseFloat(quantity) + parseFloat(1);
      }

      setDetails(data);
      setModalData();
      setSelcectedModalData();
    }
  };

  const handleDescription = async (e, index) => {
    let data = [...details];

    if (e) {
      let params = {
        id: e,
      };
      const product = await get_product(params);

      if (product?.data?.product_units_details?.length > 0) {
        setOpen(true);
        setModalData([
          product?.data?.unit,
          ...product?.data?.product_units_details,
        ]);

        setSelcectedModalData({ ...product, index: index });
      } else {
        let description_ids = [];

        data?.map((v, i) => {
          description_ids?.push(v?.description);
        });

        if (!description_ids?.includes(e)) {
          let product_description = product?.data?._id;
          let product_name = product?.data?.name;
          let product_unit = product?.data?.unit?._id;
          let product_unit_name = product?.data?.unit?.name;

          let product_unit_details = [];
          if (product?.data?.product_units_details?.length > 0) {
            product?.data?.product_units_details?.map((value, index) => {
              product_unit_details?.push({
                ...value,
                name: value?.name?.name,
                price_per_unit: 0,
                sale_price: "",
                unit_quantity: 0,
                unit_delivered: 0,
              });
            });
          }

          data[index].description = product_description;
          data[index].name = product_name;
          data[index].unit = product_unit;
          data[index].unit_name = product_unit_name;
          data[index].quantity = 1;
          data[index].unit_details_options = product_unit_details;
          setDetails(data);
        } else {
          let selected_index = description_ids?.indexOf(e);
          let quantity = data[selected_index].quantity
            ? data[selected_index].quantity
            : 0;

          data[selected_index].quantity = parseFloat(quantity) + parseFloat(1);
          setDetails(data);
        }
      }
    } else {
      data[index].id = "";
      data[index].description = null;
      data[index].name = "";
      data[index].unit = "";
      data[index].unit_name = "";
      data[index].unit_details_options = null;
      data[index].quantity = "";
      data[index].conversion = 0;
      data[index].purchase = "";
      data[index].purchase_price = "";
      data[index].purchase_options = null;
      data[index].delivered = "";
      data[index].tax = 0;
      data[index].free = "";
      data[index].barcode = "";
      data[index].price_per_unit = "";
      data[index].sale_price = "";
      data[index].expiry_date = "";
      data[index].tax_amount = "0.000";
      data[index].total = 0;
      setDetails(data);
    }
  };

  const handlePurchasePriceSearch = async (e, index) => {
    let data = [...details];

    if (data[index].description) {
      const select = {
        api: "get-all-purchase-details",
        id: data[index].description,
      };

      const search = await select_id(select);
      if (search?.status) {
        let options = [];
        search?.data?.map((value, index) => {
          options?.push({
            value: JSON?.stringify(value),
            label:
              "" +
              value?.price_per_unit?.toFixed(3) +
              " (" +
              value?.purchase?.supplier?.name +
              ", " +
              moment?.(value?.purchase?.date)?.format?.("DD/MM/YYYY") +
              ", #PO" +
              value?.purchase?.number +
              ")",
          });
        });

        data[index].purchase_options = options;
        setDetails(data);
      } else {
        toast?.error(search?.message);
      }
    }
  };

  const handleTotal = (e, index, key) => {
    let name = key ? key : e?.target?.name;
    let value = key && e ? e : !key && e?.target?.value ? e?.target?.value : "";

    if (value >= 0) {
      let data = [...details];

      let data_purchase_price = data[index]?.purchase_price
        ? data[index]?.purchase_price
        : 0;
      let data_quantity = data[index]?.quantity ? data[index]?.quantity : 0;
      let data_deliverd = data[index]?.delivered ? data[index]?.delivered : 0;
      let data_free = data[index]?.free ? data[index]?.free : 0;
      let data_tax = data[index]?.tax ? data[index]?.tax : 0;
      let data_conversion = data[index].conversion ? data[index].conversion : 0;

      let purchase_price =
        name === "purchase_price" ? value : data_purchase_price;
      let quantity = name === "quantity" ? value : data_quantity;
      let delivered = name === "quantity" ? "" : data_deliverd;
      let free = name === "free" ? value : data_free;
      let tax = name === "tax" ? value : data_tax;

      let tax_amount = tax
        ? parseFloat(purchase_price) *
          parseFloat(quantity ? quantity : 0) *
          (parseFloat(tax) / 100)
        : 0;

      let total =
        parseFloat(purchase_price) * parseFloat(quantity ? quantity : 0) +
        parseFloat(tax_amount);

      let price_per_unit =
        parseFloat(total ? total : 0) /
        (parseFloat(quantity ? quantity : 0) + parseFloat(free ? free : 0));

      let unit_details_options = [];
      if (data?.[index]?.unit_details_options?.length > 0) {
        data?.[index]?.unit_details_options?.map((v, i) => {
          let total_quantity =
            parseFloat(quantity || 0) + parseFloat(free || 0);

          unit_details_options?.push({
            ...v,
            //price_per_unit
            price_per_unit: price_per_unit
              ? parseFloat(price_per_unit || 0) / parseFloat(v?.conversion || 0)
              : 0,
            //unit_quantity
            unit_quantity: quantity
              ? parseFloat(v?.conversion || 0) * parseFloat(total_quantity || 0)
              : 0,
          });
        });
      }

      if (
        data_conversion > 0 &&
        parseFloat(quantity) + parseFloat(free) >= data_conversion
      ) {
        toast?.error(`Quantiy exceeding ${data[index].conversion}`);
      } else {
        data[index][name] = value;
        data[index].unit_details_options = unit_details_options;
        data[index].delivered = delivered ? delivered : "";
        data[index].tax = tax ? tax : 0;
        data[index].total = total ? total : 0;
        data[index].tax_amount = tax_amount ? tax_amount : "0.000";
        data[index].price_per_unit = price_per_unit ? price_per_unit : 0;

        let subtotal =
          data?.reduce(
            (acc, currentValue) =>
              parseFloat(acc) + parseFloat(currentValue?.total),
            0
          ) -
          data?.reduce(
            (acc, currentValue) =>
              parseFloat(acc) + parseFloat(currentValue?.tax_amount),
            0
          );

        let taxamount = data?.reduce(
          (acc, currentValue) =>
            parseFloat(acc) + parseFloat(currentValue?.tax_amount),
          0
        );

        let grandtotal = data?.reduce(
          (acc, currentValue) =>
            parseFloat(acc) + parseFloat(currentValue?.total),
          0
        );

        totals.subtotal = subtotal;
        totals.taxamount = taxamount;
        totals.delivery_status = 0;
        totals.delivery_date = "";
        totals.payment_status = 0;
        totals.payment_types = null;
        totals.payments = {
          Cash: "",
          Cheque: "",
          "Debit card": "",
          "Credit card": "",
          "Bank transfer": "",
          "Online payment": "",
        };
        totals.total = grandtotal;

        setDetails(data);
        setTotals({ ...totals });
      }
    }
  };

  const handleChange = (e, index, key) => {
    let data = [...details];

    data[index][e?.target?.name] = e?.target?.value;
    setDetails(data);
  };

  const handleSalePrice = (e, index) => {
    if (e?.target?.value >= 0) {
      let data = [...details];

      data[index].sale_price = e?.target?.value;

      if (data[index]?.unit_details_options?.length > 0) {
        data[index]?.unit_details_options?.map((v, i) => {
          data[index].unit_details_options[i].sale_price = (
            parseFloat(e?.target?.value) / parseFloat(v?.conversion)
          )?.toFixed(3);
        });
      }

      setDetails(data);
    }
  };

  const handleUnitsTotal = (e, index, i) => {
    let data = [...details];

    let name = e?.target?.name;
    let value = e?.target?.value;

    if (name) {
      if (value >= 0) {
        data[index].unit_details_options[i][name] = value;
        setDetails(data);
      }
    }
  };

  const handleUnitPurchasePrice = (e, index, i) => {
    let data = [...details];

    let name = e?.target?.name;
    let value = e?.target?.value;

    if (name) {
      if (value >= 0) {
        data[index].unit_details_options[i].price_per_unit = value;
        setDetails(data);
      }
    }
  };

  const handleUnitSalePrice = (e, index, i) => {
    let data = [...details];

    let name = e?.target?.name;
    let value = e?.target?.value;

    if (name) {
      if (value >= 0) {
        data[index].unit_details_options[i].sale_price = value;
        setDetails(data);
      }
    }
  };

  const handleDate = (e, index, name) => {
    let data = [...details];
    data[index][name] = e?.$d;

    setDetails(data);
  };

  const handleDelivered = (e, index) => {
    let data = [...details];

    let data_delivered = e?.target?.value ? parseFloat(e?.target?.value) : 0;

    let data_quantity = data[index]?.quantity
      ? parseFloat(data[index]?.quantity)
      : 0;
    let data_free = data[index]?.free ? parseFloat(data[index]?.free) : 0;
    let total_quantity =
      parseFloat(data_quantity || 0) + parseFloat(data_free || 0);

    if (data_delivered >= 0) {
      if (data_delivered <= total_quantity) {
        let unit_details_options = [];
        if (data?.[index]?.unit_details_options?.length > 0) {
          data?.[index]?.unit_details_options?.map((v, i) => {
            unit_details_options?.push({
              ...v,
              //delivered_stcok
              unit_delivered: data_delivered
                ? parseFloat(data_delivered) * parseFloat(v?.conversion)
                : 0,
            });
          });
        }

        data[index][e?.target?.name] = e?.target?.value;
        data[index].unit_details_options = unit_details_options;

        setDetails(data);
      } else {
        toast?.error(`Quantity only ${total_quantity}`);
      }
    }
  };

  const handleRemove = (index) => {
    let data = [...details];

    data?.splice(index, 1);

    setDetails(data);
  };

  const handleAdd = () => {
    setDetails((prevDetails) => [
      ...prevDetails,
      {
        id: "",
        description: null,
        name: "",
        unit: "",
        unit_name: "",
        unit_details_options: null,
        quantity: "",
        purchase: "",
        purchase_price: "",
        purchase_options: null,
        delivered: "",
        tax: 0,
        free: "",
        barcode: "",
        price_per_unit: "",
        sale_price: "",
        expiry_date: "",
        tax_amount: "0.000",
        total: 0,
      },
    ]);
  };

  const handleDelivery = (e) => {
    if (e?.target?.value >= 0) {
      let total_amount = parseFloat(
        details?.reduce(
          (acc, currentValue) =>
            parseFloat(acc) + parseFloat(currentValue?.total),
          0
        )
      );

      let total =
        parseFloat(total_amount ? total_amount : 0) +
        parseFloat(e?.target?.value ? e?.target?.value : 0) -
        parseFloat(totals?.discount ? totals?.discount : 0);

      totals.delivery = e?.target?.value;
      totals.total = total;

      setTotals({ ...totals });
    }
  };

  const handleDiscount = (e) => {
    if (e?.target?.value >= 0) {
      let total_amount = parseFloat(
        details?.reduce(
          (acc, currentValue) =>
            parseFloat(acc) + parseFloat(currentValue?.total),
          0
        )
      );

      let total =
        parseFloat(total_amount ? total_amount : 0) +
        parseFloat(totals?.delivery ? totals?.delivery : 0) -
        parseFloat(e?.target?.value ? e?.target?.value : 0);

      if (e?.target?.value <= total_amount) {
        totals.discount = e?.target?.value;
        totals.total = total;
      } else {
        toast?.error(`Total amount ${total_amount}`);
      }

      setTotals({ ...totals });
    }
  };

  const handleDeliveryStatus = (e) => {
    totals.delivery_status = e;

    if (!e) {
      totals.delivery_date = "";
    } else if (e == 1) {
      totals.delivery_date = "";
    }

    setTotals({ ...totals });
  };

  const handleDeliveryDate = (e) => {
    totals.delivery_date = e?.$d;
    setTotals({ ...totals });
  };

  const handlePaymentStatus = (e) => {
    totals.payment_status = e;

    if (!e) {
      totals.payment_types = null;
    }
    setTotals({ ...totals });
  };

  const handlePaymentTypes = (e) => {
    totals?.payment_types?.map((v, i) => {
      if (!e?.includes(v)) {
        totals.payments[v] = "";
      }
    });

    totals.payment_types = e;

    setTotals({ ...totals });
  };

  const handlePayments = (e) => {
    console.log(e?.target?.name, e?.target?.value);
    let name = e?.target?.name;
    let value = e?.target?.value ? e?.target?.value : "";

    if (e?.target?.value >= 0) {
      let data_Cash = totals?.payments?.Cash?.amount
        ? totals?.payments?.Cash?.amount
        : 0;
      let data_Cheque = totals?.payments?.Cheque?.amount
        ? totals?.payments?.Cheque?.amount
        : 0;
      let data_Debitcard = totals?.payments?.["Debit card"]?.amount
        ? totals?.payments?.["Debit card"]?.amount
        : 0;
      let data_Creditcard = totals?.payments?.["Credit card"]?.amount
        ? totals?.payments?.["Credit card"]?.amount
        : 0;
      let data_Banktransfer = totals?.payments?.["Bank transfer"]?.amount
        ? totals?.payments?.["Bank transfer"]?.amount
        : 0;
      let data_Onlinepayment = totals?.payments?.["Online payment"]?.amount
        ? totals?.payments?.["Online payment"]?.amount
        : 0;

      let Cash = name === "Cash" ? value : data_Cash;
      let Cheque = name === "Cheque" ? value : data_Cheque;
      let Debitcard = name === "Debit card" ? value : data_Debitcard;
      let Creditcard = name === "Credit card" ? value : data_Creditcard;
      let Banktransfer = name === "Bank transfer" ? value : data_Banktransfer;
      let Onlinepayment =
        name === "Online payment" ? value : data_Onlinepayment;

      let total =
        parseFloat(Cash) +
        parseFloat(Cheque) +
        parseFloat(Debitcard) +
        parseFloat(Creditcard) +
        parseFloat(Banktransfer) +
        parseFloat(Onlinepayment);

      let payment_total = total ? parseFloat(total) : 0;
      let grand_total = totals?.total ? parseFloat(totals?.total) : 0;

      if (payment_total <= grand_total) {
        totals.payments[name] = { ...totals.payments[name], amount: value };
        setTotals({ ...totals });
      } else {
        let previous_total =
          parseFloat(data_Cash) +
          parseFloat(data_Cheque) +
          parseFloat(data_Debitcard) +
          parseFloat(data_Creditcard) +
          parseFloat(data_Banktransfer) +
          parseFloat(data_Onlinepayment);

        let previous_remaining_amount =
          parseFloat(grand_total) - parseFloat(previous_total);

        toast?.error(`Total only ${grand_total}`);
      }
    }
  };

  return (
    <div>
      <Modalcomponent
        open={open}
        setOpen={setOpen}
        modalData={modalData}
        setModalData={setModalData}
        selcectedModalData={selcectedModalData}
        setSelcectedModalData={setSelcectedModalData}
        handleModal={handleModal}
      />
      <table className="w-full">
        <thead>
          <tr>
            <td className="border p-4 text-black">S.No</td>
            <td className="border p-4 text-black">Description</td>
            <td className="border p-4 text-black">Unit</td>
            <td className="border p-4 text-black">Purchase Price</td>
            <td className="border p-4 text-black">Quantity</td>
            <td className="border p-4 text-black">Delivered</td>
            <td className="border p-4 text-black">Free</td>
            <td className="border p-4 text-black">Tax</td>
            <td className="border p-4 text-black">Total</td>
            <td className="border p-4 text-black">Delete</td>
          </tr>
        </thead>
        <tbody>
          {details?.map((value, index) => {
            focus.current[`description${index}`] = createRef();
            focus.current[`purchase_price${index}`] = createRef();
            focus.current[`quantity${index}`] = createRef();
            focus.current[`sale_price${index}`] = createRef();
            return (
              <React.Fragment>
                <tr>
                  <td className="border p-4 text-center">{index + 1}</td>
                  <td className="border p-4">
                    {value?.description ? (
                      value?.name
                    ) : (
                      <div>
                        <Select
                          className="w-[200px] text-regular"
                          name="description"
                          ref={focus.current[`description${index}`]}
                          placeholder="Description"
                          allowClear={true}
                          showSearch={true}
                          filterOption={false}
                          value={value?.description}
                          options={value?.options}
                          onSearch={(e) => handleSearch(e, index)}
                          onClick={(e) => handleSearch("", index)}
                          onChange={(e) => handleDescription(e, index)}
                          autoComplete="off"
                        />
                      </div>
                    )}
                    <Error id={`description${index}`} />
                  </td>
                  <td className="border p-4 text-center">
                    {/* <div>{value?.unit}</div> */}
                    <div>{value?.unit_name}</div>
                  </td>
                  <td className="border p-4 text-center">
                    <div>
                      <div>
                        <AutoComplete
                          className="w-[325px] text-regular"
                          name="purchase_price"
                          ref={focus.current[`purchase_price${index}`]}
                          placeholder={`Purchase price`}
                          allowClear={true}
                          showSearch={true}
                          filterOption={false}
                          options={value?.purchase_options}
                          value={value?.purchase_price}
                          onClick={(e) => handlePurchasePriceSearch("", index)}
                          onChange={(e) =>
                            handleTotal(e, index, "purchase_price")
                          }
                          autoComplete="off"
                        />
                        <Error
                          className="pt-0.5 text-xs text-red-500 text-left"
                          id={`purchase_price${index}`}
                        />
                      </div>
                    </div>
                  </td>
                  <td className="border p-4 text-center">
                    <div>
                      <Input
                        type="number"
                        className="w-full text-regular"
                        name="quantity"
                        ref={focus.current[`quantity${index}`]}
                        placeholder="Quantity"
                        value={value?.quantity}
                        onChange={(e) => handleTotal(e, index)}
                        autoComplete="off"
                      />
                      <div className="">
                        <Error id={`quantity${index}`} />
                      </div>
                    </div>
                  </td>
                  <td className="border p-4 text-center">
                    <Input
                      type="number"
                      className="w-full text-regular"
                      name="delivered"
                      placeholder="Delivered"
                      value={value?.delivered}
                      onChange={(e) => handleDelivered(e, index)}
                      autoComplete="off"
                    />
                  </td>
                  <td className="border p-4 text-center">
                    <Input
                      type="number"
                      className="w-full text-regular"
                      name="free"
                      placeholder="Free"
                      value={value?.free}
                      onChange={(e) => handleTotal(e, index)}
                      autoComplete="off"
                    />
                  </td>
                  <td className="border p-4 text-center">
                    <Select
                      className="w-full text-regular"
                      name="tax"
                      placeholder="Tax"
                      options={taxes}
                      value={value?.tax}
                      onChange={(e) => handleTotal(e, index, "tax")}
                    />
                  </td>
                  <td className="border p-4 text-center">
                    {value?.total
                      ? parseFloat?.(value?.total)?.toFixed(3)
                      : "0.000"}
                  </td>
                  <td className="border p-4 text-center">
                    <button
                      type="button"
                      className="text-lg text-red-500 cursor-pointer hover:text-red-800"
                      onClick={() => handleRemove(index)}
                    >
                      <DeleteOutlined />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td className="border p-4 text-center"></td>
                  <td className="border p-4">
                    <div className="pb-2">Barcode</div>
                    <Input
                      type="text"
                      className="w-full text-regular"
                      name="barcode"
                      placeholder="Barcode"
                      value={value?.barcode}
                      onChange={(e) => handleChange(e, index)}
                      autoComplete="off"
                    />
                  </td>
                  <td className="border p-4">
                    <div className="pb-2">Price/Unit</div>
                    <div className="border border-gray-300 p-1 rounded-md">
                      {value?.price_per_unit
                        ? parseFloat(value?.price_per_unit)?.toFixed(3)
                        : "0.000"}
                    </div>
                    {/* unit details price per unit*/}
                    <div>
                      {value?.unit_details_options?.map((v, i) => {
                        return (
                          <div className="pt-2">
                            <div className="pb-2">
                              {v?.name}({v?.conversion})
                            </div>
                            <div className="border border-gray-300 p-1 rounded-md">
                              {v?.price_per_unit
                                ? parseFloat?.(v?.price_per_unit)?.toFixed(3)
                                : "0.000"}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </td>
                  <td className="border p-4">
                    <div>
                      <div className="pb-2">Sale price</div>
                      <Input
                        type="number"
                        className="w-full text-regular"
                        name="sale_price"
                        ref={focus.current[`sale_price${index}`]}
                        placeholder="Sale price"
                        value={value?.sale_price}
                        onChange={(e) => handleSalePrice(e, index)}
                        autoComplete="off"
                      />
                      <Error id={`sale_price${index}`} />
                      {/* units details sale price */}
                      <div>
                        {value?.unit_details_options?.map((v, i) => {
                          focus.current[`sale_price${index}${i}`] = createRef();
                          return (
                            <div>
                              <div className="pt-1">
                                {v?.name}({v?.conversion})
                              </div>
                              <div className="pt-2">
                                <Input
                                  type="number"
                                  className="w-full text-regular"
                                  name="sale_price"
                                  ref={focus.current[`sale_price${index}${i}`]}
                                  placeholder={`Sale price`}
                                  value={v?.sale_price}
                                  onChange={(e) =>
                                    handleUnitSalePrice(e, index, i)
                                  }
                                  autoComplete="off"
                                />
                                <Error id={`sale_price${index}${i}`} />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </td>
                  <td className="border p-4">
                    {/* units details unit_quantity */}
                    <div>
                      {value?.unit_details_options?.map((v, i) => {
                        return (
                          <div className="pt-2">
                            <div className="pb-2">
                              {v?.name}({v?.conversion})
                            </div>
                            <div>
                              <div className="border border-gray-300 p-1 rounded-md">
                                <div>{v?.unit_quantity}</div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </td>
                  <td className="border p-4">
                    {/* units details unit_delivered */}
                    <div>
                      {value?.unit_details_options?.map((v, i) => {
                        return (
                          <div className="pt-2">
                            <div className="pb-2">
                              {v?.name}({v?.conversion})
                            </div>
                            <div>
                              <div className="border border-gray-300 p-1 rounded-md">
                                <div>{v?.unit_delivered}</div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </td>
                  <td className="border p-4">
                    <div className="pb-2">Expiry</div>
                    <DatePicker
                      type="number"
                      className="w-full text-regular"
                      name="expiry_date"
                      placeholder="Expiry"
                      value={value?.expiry_date && dayjs(value?.expiry_date)}
                      onChange={(e) => handleDate(e, index, "expiry_date")}
                      autoComplete="off"
                      // required="required"
                    />
                  </td>
                  <td className="border p-4">
                    <div className="pb-2">Taxamount</div>
                    <div>
                      <div className="border border-gray-300 p-1 rounded-md">
                        {value?.tax_amount
                          ? parseFloat(value?.tax_amount)?.toFixed(3)
                          : "0.000"}
                      </div>
                    </div>
                  </td>
                  <td className="border p-4 text-center" colSpan={2}>
                    {details?.length == index + 1 && (
                      <AddButton onClick={handleAdd} />
                    )}
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td className="border p-4" colSpan={7}></td>
            <td className="border p-4">Subtotal</td>
            <td className="border p-4" colSpan={2}>
              {totals?.subtotal > 0 ? totals?.subtotal?.toFixed(3) : "0.000"}
            </td>
          </tr>
          <tr>
            <td className="border p-4" colSpan={7}></td>
            <td className="border p-4">Tax&nbsp;amount</td>
            <td className="border p-4" colSpan={2}>
              {totals?.taxamount > 0 ? totals?.taxamount?.toFixed(3) : "0.000"}
            </td>
          </tr>
          <tr>
            <td className="border p-4" colSpan={7}></td>
            <td className="border p-4">Discount&nbsp;</td>
            <td className="border p-4" colSpan={2}>
              <Input
                type="number"
                className="w-full"
                placeholder="Discount amount"
                name="discount"
                value={totals?.discount}
                autoComplete="off"
                onChange={handleDiscount}
              />
            </td>
          </tr>
          <tr>
            <td className="border p-4" colSpan={7}></td>
            <td className="border p-4">Delivery&nbsp;</td>
            <td className="border p-4" colSpan={2}>
              <Input
                type="number"
                className="w-full"
                placeholder="Delivery charges"
                name="delivery"
                value={totals?.delivery}
                autoComplete="off"
                onChange={handleDelivery}
              />
            </td>
          </tr>
          <tr>
            <td className="border p-4" colSpan={7}></td>
            <td className="border p-4">Delivery status&nbsp;</td>
            <td className="border p-4" colSpan={2}>
              <div>
                <div>
                  <Select
                    className="w-full"
                    name="tax"
                    placeholder="Delivery status"
                    value={totals?.delivery_status}
                    options={[
                      {
                        value: 0,
                        label: "Pending",
                      },
                      {
                        value: 1,
                        label: "Partial",
                      },
                      {
                        value: 2,
                        label: "Delivered",
                      },
                    ]}
                    onChange={handleDeliveryStatus}
                  />
                </div>
                {/* {focus.current[`delivery_date`] = createRef()} */}
                {totals?.delivery_status == 2 && (
                  <div className="pt-4">
                    <DatePicker
                      type="number"
                      name="delivery_date"
                      ref={focus.current[`delivery_date`]}
                      className="w-full"
                      placeholder="Delivered date"
                      value={
                        totals?.delivery_date && dayjs(totals?.delivery_date)
                      }
                      onChange={handleDeliveryDate}
                      autoComplete="off"
                    />
                    <Error id={`delivery_date`} />
                  </div>
                )}
              </div>
            </td>
          </tr>
          <tr>
            <td className="border p-4" colSpan={7}></td>
            <td className="border p-4">Payment status&nbsp;</td>
            <td className="border p-4" colSpan={2}>
              <div>
                <Select
                  className="w-full"
                  name="tax"
                  placeholder="Payment status"
                  value={totals?.payment_status}
                  options={[
                    {
                      value: 0,
                      label: "Unpaid",
                    },
                    {
                      value: 1,
                      label: "Partial",
                    },
                    {
                      value: 2,
                      label: "Paid",
                    },
                  ]}
                  onChange={handlePaymentStatus}
                />
              </div>
              {totals?.payment_status == 1 || totals?.payment_status == 2 ? (
                <div className="pt-4">
                  <Select
                    mode="multiple"
                    className="w-full"
                    name="payment_types"
                    ref={focus.current[`payment_types`]}
                    placeholder="Payment types"
                    value={totals?.payment_types}
                    options={payment_types}
                    onChange={handlePaymentTypes}
                  />
                  <Error id={`payment_types`} />
                </div>
              ) : (
                ""
              )}
              {totals?.payment_types?.map((value, index) => {
                focus.current[`${value}`] = createRef();
                return (
                  <div className="pt-4">
                    <Input
                      type="number"
                      className="w-full"
                      placeholder={value}
                      name={value}
                      ref={focus.current[`${value}`]}
                      value={totals?.payments?.[value]?.amount}
                      onChange={handlePayments}
                      autoComplete="off"
                    />
                    <Error id={`${value}`} />
                  </div>
                );
              })}
            </td>
          </tr>
          <tr>
            <td className="border p-4" colSpan={7}></td>
            <td className="border p-4 text-black text-medium">Total</td>
            <td className="border p-4 text-black text-medium" colSpan={2}>
              {totals?.total > 0 ? totals?.total?.toFixed(3) : "0.000"}
            </td>
          </tr>
        </tfoot>
      </table>
      <div className="flex justify-end p-3">
        <AddButton onClick={handleAdd} />
      </div>
    </div>
  );
});

export default Purchaseordertable;
