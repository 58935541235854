import React from "react";

const Error = (props) => {
  const id = props?.id;
  const name = props?.name;
  const className = props?.className;

  return (
    <div
      id={id}
      name={name}
      className={className ? className : "pt-0.5 text-xs text-red-500"}
    />
  );
};

export default Error;
