import React, { useEffect, useState } from "react";
import Header from "../../../../Componets/Header";
import Status from "../../../../Componets/Status";
import ActionButton from "../../../../Componets/Buttons/action";
import Table from "../../../../Componets/Table";
import toast from "react-hot-toast";
import tables from "../../../../Data/Tables/Products/Brands/tables";
import headers from "../../../../Data/Tables/Products/Brands/headers";
import { get_all_product_brands } from "../../../../Controllers/Products/Brands";
import Loadercomponent from "../../../../Componets/Loader";

const BrandsTable = () => {
  const [loader, setLoader] = useState(false);

  const [header, setHeader] = useState(headers);

  const [body, setBody] = useState();

  const formdata = (data) => {
    fetchData(data);
  };

  const actiondata = (data) => {
    fetchData(data);
  };

  const fetchData = async (brand) => {
    try {
      setLoader(true);

      const params = {
        search: brand?.search,
        status: brand?.status,
      };

      const brands = await get_all_product_brands(params);

      if (brands?.status) {
        let data = [];

        brands?.data?.map((value, index) => {
          data?.push({
            Name: value?.name,
            Status: <Status status={value?.status} />,
            Action: (
              <ActionButton
                edit={`/app/products/brands/edit/${value?._id}`}
                id={value?._id}
                api="delete-product-brand"
                actiondata={actiondata}
              />
            ),
          });
        });

        setBody(data);
        setLoader(false);
      } else {
        toast?.error(brands?.message);
        setLoader(false);
      }
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    // <Header header={headers} formdata={formdata}>
    <Header headers={header} setHeaders={setHeader} formdata={formdata}>
      <Loadercomponent loader={loader}>
        <Table head={tables} body={body} />
      </Loadercomponent>
    </Header>
  );
};

export default BrandsTable;
