import React, { useEffect, useState } from "react";
import Header from "../../../../Componets/Header";
import Table from "../../../../Componets/Table";
import tables from "../../../../Data/Tables/Inventory/Expiry/tables";
import headers from "../../../../Data/Tables/Inventory/Expiry/headers";
import toast from "react-hot-toast";

const InventoryExpiryTable = () => {
  let header = headers;
  let head = tables;

  const [body, setBody] = useState();

  const fetchData = async () => {
    try {
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  const formdata = async (data) => {
    console.log(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={header} formdata={formdata}>
      <Table head={head} body={body} />
    </Header>
  );
};

export default InventoryExpiryTable;
