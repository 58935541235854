import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import Header from "../../../../Componets/Header";
import forms from "../../../../Data/Forms/Purchases//Received/forms";
import headers from "../../../../Data/Forms/Purchases/Received/headers";
import products from "../../../../Data/products.json";
import inventory from "../../../../Data/inventory.json";
import purchases from "../../../../Data/purchases.json";

const PurchasesReceivedForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [values, setValues] = useState(forms);

  const get_data = () => {
    console.log("wdwdwd");
    let item_options = [];
    inventory?.map((value, index) => {
      item_options?.push({
        value: value?.batch_number,
        label: get_product(value?.product) + " (" + value?.batch_number + ") ",
      });
    });

    let body_options = [];
    inventory?.map((value, index) => {
      if (index < 4) {
        let tax_amount =
          parseFloat(value?.stock) *
          parseFloat(value?.purchase_price) *
          (parseFloat(value?.tax) / 100);

        body_options?.push({
          Description: {
            type: "select",
            form: {
              name: "description",
              allowClear: true,
              showSearch: true,
              filterOption: true,
              className: "w-[400px]",
              placeholder: "Select Description",
              value:
                get_product(value?.product) + " (" + value?.batch_number + ") ",
              options: item_options,
              api: "get-all-items",
              required: true,
            },
          },
          "Total quantity": {
            type: "text",
            form: {
              name: "quantity",
              type: "number",
              placeholder: "Description quantity",
              value: value?.stock,
              disabled: true,
              required: true,
            },
          },
          Price: {
            type: "text",
            form: {
              name: "price",
              type: "number",
              placeholder: "Description price",
              value: value?.purchase_price,
              disabled: true,
              required: true,
            },
          },
          Quantity: {
            type: "input",
            form: {
              name: "unit",
              value: "",
            },
          },
          Tax: {
            type: "text",
            form: {
              name: "tax",
              type: "number",
              placeholder: "Description tax",
              value: value?.tax,
              disabled: true,
              required: false,
            },
          },
          "Tax amount": {
            type: "text",
            form: {
              name: "tax_amount",
              value: tax_amount?.toFixed(3),
            },
          },
          Total: {
            type: "text",
            form: {
              name: "total",
              value: 0,
            },
          },
        });
      }
    });

    values[1].options[0].form.body = body_options;
    setValues([...values]);
  };

  const formdata = async (data) => {
    console.log(data);
    if (data?.[0]?.purchase) {
      get_data();
    }
  };

  const handleData = (item) => {};

  const get_product = (id) => {
    let product = "";
    products?.map((value, index) => {
      if (id == value?.id) {
        product = value?.name;
      }
    });

    return product;
  };

  const fetchData = async () => {
    let purchase_options = [];
    purchases?.map((value, index) => {
      purchase_options?.push({
        value: value?.number,
        label: value?.number,
      });
    });

    // let item_options = [];
    // inventory?.map((value, index) => {
    //   item_options?.push({
    //     value: value?.batch_number,
    //     label: get_product(value?.product) + " (" + value?.batch_number + ") ",
    //   });
    // });

    // let body_options = [];
    // inventory?.map((value, index) => {
    //   if (index < 4) {
    //     let tax_amount =
    //       parseFloat(value?.stock) *
    //       parseFloat(value?.purchase_price) *
    //       (parseFloat(value?.tax) / 100);

    //     body_options?.push({
    //       Description: {
    //         type: "select",
    //         form: {
    //           name: "description",
    //           allowClear: true,
    //           showSearch: true,
    //           filterOption: true,
    //           className: "w-[400px]",
    //           placeholder: "Select Description",
    //           value:
    //             get_product(value?.product) + " (" + value?.batch_number + ") ",
    //           options: item_options,
    //           api: "get-all-items",
    //           required: true,
    //         },
    //       },
    //       "Total quantity": {
    //         type: "text",
    //         form: {
    //           name: "quantity",
    //           type: "number",
    //           placeholder: "Description quantity",
    //           value: value?.stock,
    //           disabled: true,
    //           required: true,
    //         },
    //       },
    //       Price: {
    //         type: "text",
    //         form: {
    //           name: "price",
    //           type: "number",
    //           placeholder: "Description price",
    //           value: value?.purchase_price,
    //           disabled: true,
    //           required: true,
    //         },
    //       },
    //       Quantity: {
    //         type: "input",
    //         form: {
    //           name: "unit",
    //           value: "",
    //         },
    //       },
    //       Tax: {
    //         type: "text",
    //         form: {
    //           name: "tax",
    //           type: "number",
    //           placeholder: "Description tax",
    //           value: value?.tax,
    //           disabled: true,
    //           required: false,
    //         },
    //       },
    //       "Tax amount": {
    //         type: "text",
    //         form: {
    //           name: "tax_amount",
    //           value: tax_amount?.toFixed(3),
    //         },
    //       },
    //       Total: {
    //         type: "text",
    //         form: {
    //           name: "total",
    //           value: 0,
    //         },
    //       },
    //     });
    //   }
    // });

    let product_options = [];
    inventory?.map((value, index) => {
      product_options?.push(value);
    });

    values[0].options[0].form.options = purchase_options;
    // values[1].options[0].form.body = body_options;
    values[1].options[0].form.options = product_options;
    setValues([...values]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Forms width={70} form={values} setForm={setValues} formdata={formdata} />
    </Header>
  );
};

export default PurchasesReceivedForm;
