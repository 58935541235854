import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Select } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const Table = (props) => {
  const { path, head, body, pagination, paginationdata } = props;

  const navigate = useNavigate();

  const redirect = (path) => {
    if (path) {
      navigate(path);
    }
  };

  let show = [
    { value: 10, label: 10 },
    { value: 20, label: 20 },
    { value: 30, label: 30 },
    { value: 40, label: 40 },
    { value: 50, label: 50 },
  ];

  const handleNext = () => {
    let currentPage = parseInt(pagination?.currentPage) + 1;

    if (currentPage <= parseInt(pagination?.totalPages)) {
      let data = {
        ...pagination,
        currentPage: currentPage,
      };

      paginationdata(data);
    }
  };

  const handlePrevious = () => {
    let currentPage = parseInt(pagination?.currentPage) - 1;

    if (currentPage > 0) {
      let data = {
        ...pagination,
        currentPage: currentPage,
      };

      paginationdata(data);
    }
  };

  return (
    <table className="w-full bg-white rounded-md">
      {/* head */}
      {head?.length > 0 && (
        <thead>
          <tr className="border-b">
            {head?.map((value, index) => {
              return (
                <td
                  className={
                    index === head?.length - 1
                      ? "p-4 text-gray-500 text-center"
                      : value?.class
                      ? value?.class
                      : "p-4 text-gray-500"
                  }
                >
                  {value?.name}
                </td>
              );
            })}
          </tr>
        </thead>
      )}
      {/* body */}
      {body?.length > 0 && (
        <tbody>
          {body?.map((value, index) => {
            return (
              <tr
                className={
                  path
                    ? "border-b hover:bg-gray-100 cursor-pointer"
                    : "border-b hover:bg-gray-100"
                }
                onClick={() => redirect(value?.path)}
              >
                {head?.map((val, ind) => {
                  return Object?.keys(value)?.includes(val?.name) ? (
                    <td
                      className={
                        ind === head?.length - 1
                          ? "p-4 text-center"
                          : val?.className
                          ? val?.className
                          : "p-4"
                      }
                    >
                      {value?.[val?.name] ? value?.[val?.name] : "-"}
                    </td>
                  ) : (
                    <td className="p-4">-</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      )}
      {/* foot */}
      {pagination?.currentPage ? (
        <tfoot className="">
          <tr>
            <td colSpan={head?.length} className="p-4">
              <div className="flex justify-between text-gray-600 text-sm text-medium">
                <div>
                  <span className="pr-3">TOTAL</span>
                  <span className="pl-5">
                    {pagination?.currentPage > 1
                      ? pagination?.currentPage - 1 + "0"
                      : 1}{" "}
                    -{" "}
                    {parseInt(pagination?.currentPage + "0") >
                    pagination?.totalCount
                      ? pagination?.totalCount
                      : pagination?.currentPage + "0"}
                    &nbsp;OF&nbsp;{pagination?.totalCount}
                  </span>
                </div>
                <div>
                  <span>
                    <button
                      type="button"
                      className="px-4 py-1 rounded-md border-2 border-gray-300 hover:border-[#07b553]"
                      onClick={handlePrevious}
                    >
                      <div className="text-center text-xs text-gray-900">
                        <LeftOutlined />
                      </div>
                    </button>
                  </span>
                  <span className="px-3">
                    {pagination?.currentPage} OUT OF {pagination?.totalPages}
                  </span>
                  <span>
                    <button
                      className="px-4 py-1 rounded-md border-2 border-gray-300 hover:border-[#07b553]"
                      onClick={handleNext}
                    >
                      <div className="text-center text-xs text-gray-600">
                        <RightOutlined />
                      </div>
                    </button>
                  </span>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      ) : (
        ""
      )}
      {/* {body?.length > 0 && (
        <tfoot className="">
          <tr>
            <td colSpan={head?.length} className="p-4">
              <div className="flex justify-between text-gray-500">
                <div>
                  <span className="pr-3">Show</span>
                  <Select
                    className="w-20 text-regular"
                    value={10}
                    options={show}
                  />
                  <span className="pl-5">1 - 10 of 100 Items</span>
                </div>
                <div>
                  <span>
                    <Button className="px-2">
                      <div className="text-center text-xs text-gray-600">
                        <LeftOutlined />
                      </div>
                    </Button>
                  </span>
                  <span className="px-3">1 out of 10</span>
                  <span>
                    <Button className="px-2">
                      <div className="text-center text-xs text-gray-600">
                        <RightOutlined />
                      </div>
                    </Button>
                  </span>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      )} */}
    </table>
  );
};

export default Table;
