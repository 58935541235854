import React, { useEffect, useState } from "react";
import Header from "../../../../Componets/Header";
import Table from "../../../../Componets/Table";
import tables from "../../../../Data/Tables/Purchases/Received/tables";
import headers from "../../../../Data/Tables/Purchases/Received/headers";
import purchase_received from "../../../../Data/purchase_received.json";
import ActionButton from "../../../../Componets/Buttons/action";
import toast from "react-hot-toast";

const PurchasesReceivedTable = () => {
  let header = headers;
  let head = tables;

  const [body, setBody] = useState();

  const fetchData = async () => {
    try {
      let data = [];

      purchase_received?.map((value, index) => {
        data?.push({
          Purchase: value?.number,
          Date: value?.date,
          Amount: value?.amount,
          Action: <ActionButton edit={`/`} delete="/" />,
        });
      });

      setBody(data);
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  const formdata = async (data) => {
    console.log(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={header} formdata={formdata}>
      <Table head={head} body={body} />
    </Header>
  );
};

export default PurchasesReceivedTable;
