import React, { createRef, useEffect, useRef, useState } from "react";
import Inputcomponent from "../Formcomponents/input";
import Selectcomponent from "../Formcomponents/select";
import Selectaddcomponent from "../Formcomponents/selectadd";
import Uploadcomponent from "../Formcomponents/upload";
import Headercomponent from "../Formcomponents/header";
import SavebuttonComponent from "../Formcomponents/savebutton";
import Datecomponenet from "../Formcomponents/date";
import Titlecomponent from "../Formcomponents/title";
import Formdata from "../Formcomponents/formdata";
import Othercomponent from "../Formcomponents/other";
import Formcomponent from "../Formcomponents/component";
import Passwordcomponent from "../Formcomponents/password";
import Submitbutton from "../Formcomponents/submitbutton";
import Bodycomponent from "../Formcomponents/body";
import { useNavigate, useParams } from "react-router-dom";

const Form = (props) => {
  const { form, setForm, getformdata, formdata, buttonLoader } = props;

  let { id } = useParams();
  let navigate = useNavigate();

  const focus = useRef([]);

  let getFormdata = () => {
    let data = {};

    form?.map((value, index) => {
      value?.options?.map((val, ind) => {
        data[val?.form?.name] = val?.form?.value;
      });
    });

    if (getformdata) {
      getformdata(data);
    }

    return data;
  };

  const validate = (data) => {
    let total = data?.length;
    let count = 0;

    let validated = {};
    data?.map((value, index) => {
      let ind = value?.index + "" + value?.ind;

      if (!value?.value && value?.required) {
        document.getElementById(
          value?.name?.concat(ind)
        ).textContent = `${value?.name} is required`;
        if (index == count) {
          focus.current[value?.name?.concat(ind)].current.focus();
        }
      } else if (value?.value && value?.required) {
        document.getElementById(value?.name?.concat(ind)).textContent = "";
        validated[value?.name] = value?.value;
        count++;
      } else {
        validated[value?.name] = value?.value;
        count++;
      }
    });

    if (total == count) {
      return { status: true, data: validated };
    } else {
      return { status: false, data: validated };
    }
  };

  const handleSubmit = (e) => {
    e?.preventDefault();

    let data = [];
    form?.map((value, index) => {
      value?.options?.map((val, ind) => {
        data?.push({ index: index, ind: ind, ...val?.form });
      });
    });

    let validated = validate(data);
    formdata(validated);
  };

  const handleReset = (e) => {
    e?.preventDefault();

    form?.map((value, index) => {
      value?.options?.map((val, ind) => {
        if (form[index].options[ind].type == "add") {
          form[index].options[ind].form.value = null;
        } else if (
          form[index].options[ind].type == "input" ||
          form[index].options[ind].type == "password" ||
          form[index].options[ind].type == "upload"
        ) {
          form[index].options[ind].form.value = "";
        } else if (form[index].options[ind].type == "select") {
          form[index].options[ind].form.value =
            form[index].options[ind].form.name == "status"
              ? 1
              : form[index].options[ind].form.name == "tax"
              ? 0
              : form[index].options[ind].form.name == "type"
              ? 1
              : form[index].options[ind].form.name == "country"
              ? "Oman"
              : form[index].options[ind].form.name == "project_status"
              ? 1
              : form[index].options[ind].form.name == "task_status"
              ? 1
              : form[index].options[ind].form.name == "issue_status"
              ? 1
              : null;
        } else if (form[index].options[ind].type == "selectandadd") {
          form[index].options[ind].form.value = null;
        } else if (form[index].options[ind].type == "date") {
          form[index].options[ind].form.value = "";
        }
      });
    });

    setForm([...form]);
  };

  const handleBack = (e) => {
    handleReset();
    navigate(-1);
  };

  useEffect(() => {
    if (!id) {
      handleReset();
    }
  }, []);

  return (
    <React.Fragment>
      <Formcomponent form={form} onReset={handleReset} onSubmit={handleSubmit}>
        <Bodycomponent form={form}>
          {form?.map((value, index) => {
            return (
              <div>
                <Titlecomponent index={index} title={value?.name} />
                <Formdata value={value}>
                  {value?.options?.map((val, ind) => {
                    focus.current[val?.form?.name?.concat(index + "" + ind)] =
                      createRef();
                    return (
                      <div>
                        <Headercomponent
                          index={index}
                          ind={ind}
                          name={val?.name}
                          required={val?.form?.required}
                        />
                        <div className="pb-4">
                          {val?.type === "input" ? (
                            <Inputcomponent
                              index={index}
                              ind={ind}
                              ref={focus}
                              val={val}
                              form={form}
                              setForm={setForm}
                              getFormdata={getFormdata}
                            />
                          ) : val?.type === "password" ? (
                            <Passwordcomponent
                              index={index}
                              ind={ind}
                              ref={focus}
                              val={val}
                              form={form}
                              setForm={setForm}
                              getFormdata={getFormdata}
                            />
                          ) : val?.type === "select" ? (
                            <Selectcomponent
                              index={index}
                              ind={ind}
                              ref={focus}
                              val={val}
                              form={form}
                              setForm={setForm}
                              getFormdata={getFormdata}
                            />
                          ) : val?.type === "selectandadd" ? (
                            <Selectaddcomponent
                              index={index}
                              ind={ind}
                              ref={focus}
                              val={val}
                              form={form}
                              setForm={setForm}
                              getFormdata={getFormdata}
                            />
                          ) : val?.type === "date" ? (
                            <Datecomponenet
                              index={index}
                              ind={ind}
                              ref={focus}
                              val={val}
                              form={form}
                              setForm={setForm}
                              getFormdata={getFormdata}
                            />
                          ) : val?.type === "upload" ? (
                            <Uploadcomponent
                              index={index}
                              ind={ind}
                              ref={focus}
                              val={val}
                              form={form}
                              setForm={setForm}
                              getFormdata={getFormdata}
                            />
                          ) : val?.type === "component" ? (
                            <Othercomponent>{val?.form}</Othercomponent>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    );
                  })}
                </Formdata>
              </div>
            );
          })}
          <Othercomponent>{props?.children}</Othercomponent>
          <div className="py-6">
            {form?.[0]?.button ? (
              <Othercomponent>{form?.[0]?.button}</Othercomponent>
            ) : (
              <SavebuttonComponent
                handleBack={handleBack}
                buttonLoader={buttonLoader}
              />
            )}
          </div>
        </Bodycomponent>
      </Formcomponent>
    </React.Fragment>
  );
};

export default Form;
