import React, { createRef, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import toast from "react-hot-toast";
import Header from "../../../../Componets/Header";
import {
  create_purchase,
  get_purchase,
  update_purchase,
} from "../../../../Controllers/Purchases/Orders";
import forms from "../../../../Data/Forms/Purchases/Orders/forms";
import headers from "../../../../Data/Forms/Purchases/Orders/headers";
import Loadercomponent from "../../../../Componets/Loader";
import Headercomponent from "../../../../Componets/Formcomponents/header";
import Purchaseordertable from "../../../../Data/Forms/Purchases/Orders/table";
import moment from "moment";
import Formdata from "../../../../Componets/Formcomponents/formdata";
import Bodycomponent from "../../../../Componets/Formcomponents/body";
import Titlecomponent from "../../../../Componets/Formcomponents/title";

const PurchasesOrderForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const focus = useRef([]);

  const [details, setDetails] = useState([
    {
      id: "",
      description: null,
      name: "",
      unit: "",
      unit_name: "",
      unit_details_options: null,
      conversion: 0,
      quantity: "",
      purchase: "",
      purchase_price: "",
      purchase_options: null,
      delivered: "",
      tax: 0,
      free: "",
      barcode: "",
      price_per_unit: "",
      sale_price: "",
      expiry_date: "",
      tax_amount: "0.000",
      total: 0,
    },
  ]);

  const [totals, setTotals] = useState({
    subtotal: "0.000",
    taxamount: "0.000",
    discount: 0,
    delivery: 0,
    delivery_status: 0,
    delivery_date: "",
    payment_status: 0,
    payment_types: null,
    payments: {
      Cash: "",
      Cheque: "",
      "Debit card": "",
      "Credit card": "",
      "Bank transfer": "",
      "Online payment": "",
    },
    paid: 0,
    remaining: 0,
    total: "0.000",
  });

  const detailsvalidation = (tabledata) => {
    let params = {};

    if (details?.length > 0) {
      let total = details?.length;
      let count = 0;

      let validated = [];
      details?.map((v, i) => {
        if (count == i) {
          if (!v?.description) {
            document.getElementById(
              `description${i}`
            ).textContent = `Description is required`;
            focus.current[`description${i}`].current.focus();
          } else if (!v?.purchase_price) {
            document.getElementById(
              `purchase_price${i}`
            ).textContent = `Purchase price is required`;
            focus.current[`purchase_price${i}`].current.focus();
          } else if (!v?.quantity) {
            document.getElementById(
              `quantity${i}`
            ).textContent = `Quantity is required`;
            focus.current[`quantity${i}`].current.focus();
          } else if (!v?.sale_price) {
            document.getElementById(
              `sale_price${i}`
            ).textContent = `Sale price is required`;
            focus.current[`sale_price${i}`].current.focus();
          } else {
            document.getElementById(`description${i}`).textContent = ``;
            document.getElementById(`purchase_price${i}`).textContent = ``;
            document.getElementById(`quantity${i}`).textContent = ``;
            document.getElementById(`sale_price${i}`).textContent = ``;

            if (v?.unit_details_options?.length > 0) {
              let total_unit = v?.unit_details_options?.length;
              let count_unit = 0;

              v?.unit_details_options?.map((value, index) => {
                if (count_unit == index) {
                  if (!value?.sale_price) {
                    document.getElementById(
                      `sale_price${i}${index}`
                    ).textContent = `Sale price is required`;
                    focus.current[`sale_price${i}${index}`].current.focus();
                  } else {
                    document.getElementById(
                      `sale_price${i}${index}`
                    ).textContent = ``;

                    count_unit++;
                  }
                }
              });

              if (count_unit == total_unit) {
                validated?.push(v);
                count++;
              }
            } else {
              validated?.push(v);
              count++;
            }
          }
        }
      });

      if (count == total) {
        params = {
          status: true,
          data: {
            ...tabledata,
            details: validated,
          },
        };

        return params;
      } else {
        params = {
          status: false,
          data: null,
        };

        return params;
      }
    } else {
      toast?.error("Details not found");
    }
  };

  const totalsvalidation = (tabledata) => {
    let params = {};

    let count_totals = 0;

    if (totals?.delivery_status == 2 && !totals?.delivery_date) {
      document.getElementById(
        `delivery_date`
      ).textContent = `Delivery Date is required`;
      focus.current[`delivery_date`].current.focus();
    } else if (totals?.payment_status && !totals?.payment_types?.length) {
      document.getElementById(
        `payment_types`
      ).textContent = `Payment type is required`;
      focus.current[`payment_types`].current.focus();
    } else {
      if (totals?.delivery_status == 2) {
        document.getElementById(`delivery_date`).textContent = ``;
      }
      if (totals?.payment_status) {
        document.getElementById(`payment_types`).textContent = ``;
      }
      count_totals++;
    }

    //payments  validation
    if (count_totals) {
      let total_payment_types = totals?.payment_types?.length;
      let count_payment_types = 0;

      if (totals?.payment_types?.length > 0) {
        totals?.payment_types?.map((v, i) => {
          if (count_payment_types == i) {
            if (!totals?.payments?.[v]?.amount) {
              document.getElementById(`${v}`).textContent = `${v} is required`;
              focus.current[`${v}`].current.focus();
            } else {
              document.getElementById(`${v}`).textContent = ``;
              count_payment_types++;
            }
          }
        });

        if (count_payment_types == total_payment_types) {
          params = {
            status: true,
            data: {
              ...tabledata,
              id: id ? id : "",
              subtotal: totals?.subtotal,
              taxamount: totals?.taxamount,
              discount: totals?.discount,
              delivery: totals?.delivery,
              delivery_status: totals?.delivery_status,
              delivery_date: totals?.delivery_date,
              payment_status: totals?.payment_status,
              payment_types: totals?.payment_types,
              payments: totals?.payments,
              total: totals?.total,
            },
          };
          return params;
        } else {
          params = {
            status: false,
            data: null,
          };

          return params;
        }
      } else {
        params = {
          status: true,
          data: {
            ...tabledata,
            id: id ? id : "",
            subtotal: totals?.subtotal,
            taxamount: totals?.taxamount,
            discount: totals?.discount,
            delivery: totals?.delivery,
            delivery_status: totals?.delivery_status,
            delivery_date: totals?.delivery_date,
            payment_status: totals?.payment_status,
            payment_types: totals?.payment_types,
            payments: totals?.payments,
            total: totals?.total,
          },
        };

        return params;
      }
    }
  };

  const formdata = async (data) => {
    if (data?.status) {
      const tabledetailsvalidation = detailsvalidation(data?.data);

      if (tabledetailsvalidation?.status) {
        const tabletotalvalidation = totalsvalidation(
          tabledetailsvalidation?.data
        );

        if (tabletotalvalidation?.status) {
          setButtonLoader(true);
          const purchase = id
            ? await update_purchase(tabletotalvalidation?.data)
            : await create_purchase(tabletotalvalidation?.data);
          if (purchase?.status) {
            if (id) {
              navigate(-1);
            }
            toast?.success(purchase?.message);
            setButtonLoader(false);
          } else {
            toast?.error(purchase?.message);
            setButtonLoader(false);
          }
        }
      }
    }
  };

  const handleData = (purchase) => {
    let supplier = purchase?.supplier?._id ? purchase?.supplier?._id : null;
    let number = purchase?.number ? purchase?.number : "";
    let date = purchase?.date ? purchase?.date : "";
    let due_date = purchase?.due_date ? purchase?.due_date : "";
    let status = purchase?.status ? purchase?.status : 0;

    let supplier_option = purchase?.supplier?._id
      ? [
          {
            value: purchase?.supplier?._id,
            label: purchase?.supplier?.name,
          },
        ]
      : null;

    values[0].options[0].form.value = supplier;
    values[0].options[0].form.options = supplier_option;
    values[0].options[1].form.value = number;
    values[0].options[2].form.value = date;
    values[0].options[3].form.value = due_date;
    values[0].options[4].form.value = status;

    let data = [];
    purchase?.details?.map((value, index) => {
      data?.push({
        id: value?._id,
        description: value?.description?._id,
        name: value?.description?.name,
        unit: value?.unit,
        unit_name: value?.unit_name,
        unit_details_options: value?.unit_details_options,
        purchase_price: value?.purchase_price,
        quantity: value?.quantity,
        delivered: value?.delivered,
        free: value?.free,
        tax: value?.tax,
        barcode: value?.barcode,
        price_per_unit: value?.price_per_unit,
        sale_price: value?.sale_price,
        expiry_date: value?.expiry_date,
        tax_amount: value?.tax_amount,
        total: value?.total,
      });
    });

    let payment_types = [];
    let payments = {};
    purchase?.payments?.map((v, i) => {
      payment_types?.push(v?.name);
      payments[v?.name] = { id: v?._id, amount: v?.amount };
    });

    totals.subtotal = purchase?.subtotal;
    totals.taxamount = purchase?.taxamount;
    totals.discount = purchase?.discount;
    totals.delivery = purchase?.delivery;
    totals.delivery_status = purchase?.delivery_status;
    totals.delivery_date = purchase?.delivery_date;
    totals.payment_status = purchase?.payment_status;
    totals.payment_types = payment_types;
    totals.payments = payments;
    totals.total = purchase?.total;

    setValues([...values]);
    setDetails([...data]);
    setTotals({ ...totals });
    setLoader(false);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      const data = {
        id: id,
      };

      const purchase = await get_purchase(data);
      if (purchase?.status) {
        handleData(purchase?.data);
      } else {
        toast?.error(purchase?.message);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        >
          <Bodycomponent>
            <Titlecomponent index={1} title={"Items"} />
            <Formdata>
              <Headercomponent index={0} name={"Details"} required={true} />
              <Purchaseordertable
                details={details}
                setDetails={setDetails}
                totals={totals}
                setTotals={setTotals}
                ref={focus}
              />
            </Formdata>
          </Bodycomponent>
        </Forms>
      </Loadercomponent>
    </Header>
  );
};

export default PurchasesOrderForm;
