import React from "react";
import { useNavigate } from "react-router-dom";
import AddButton from "../../Componets/Buttons/add";
import DotIcon from "../../Assets/Svg/dot";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  FormOutlined,
} from "@ant-design/icons";
import ProjectIcon from "../../Assets/Svg/project";
import { icons } from "antd/es/image/PreviewGroup";
import InventoryIcon from "../../Assets/Svg/inventory";
import EmployeeIcon from "../../Assets/Svg/employee";
import CustomerIcon from "../../Assets/Svg/customer";
import SupplierIcon from "../../Assets/Svg/suppliers";
import QuoteIcon from "../../Assets/Svg/quote";
import SalesIcon from "../../Assets/Svg/sales";
import PurchaseIcon from "../../Assets/Svg/purchase";

const Reports = () => {
  let navigate = useNavigate();

  const data = [
    {
      icon: <ProjectIcon />,
      name: "projects",
      path: "/app/reports/projects",
    },
    {
      icon: <InventoryIcon />,
      name: "inventory",
      path: "/app/reports/inventory",
    },
    {
      icon: <EmployeeIcon />,
      name: "employees",
      path: "/app/reports/employees",
    },
    {
      icon: <CustomerIcon />,
      name: "customers",
      path: "/app/reports/customers",
    },
    {
      icon: <SupplierIcon />,
      name: "suppliers",
      path: "/app/reports/suppliers",
    },
    {
      icon: <QuoteIcon />,
      name: "quotes",
      path: "/app/reports/quotes",
    },
    {
      icon: <SalesIcon />,
      name: "Invoice",
      path: "/app/reports/sales",
    },
    {
      icon: <PurchaseIcon />,
      name: "purchases",
      path: "/app/reports/purchases",
    },
    {
      icon: <PurchaseIcon />,
      name: "contractors",
      path: "/app/reports/contractors",
    },
  ];

  return (
    <div>
      {/* Header Topbar Start */}
      <div className="p-4 bg-white border-b h-[60px] flex justify-between items-center">
        <div className="">
          <span className="">Reports</span>
        </div>
        <div>
          {/* <AddButton onClick={() => navigate("/app/purchases/add")} /> */}
        </div>
      </div>
      {/* Header Topbar End*/}
      <div className="p-4">
        <div className="flex flex-wrap">
          {data?.map((value, index) => {
            return (
              <div
                key={index}
                className="bg-white w-[32%] p-4 rounded-md hover:bg-gray-200 mb-5 mr-4 cursor-pointer"
                onClick={() => navigate(value?.path)}
              >
                <div className="flex items-center">
                  <div className="mr-3">{value?.icon}</div>
                  <div className="uppercase text-medium text-gray-700">
                    {value?.name}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Reports;
