import React, { useEffect, useState } from "react";
import Header from "../../../../Componets/Header";
import Table from "../../../../Componets/Table";
import tables from "../../../../Data/Tables/Inventory/Stock/tables";
import headers from "../../../../Data/Tables/Inventory/Stock/headers";
import received from "../../../../Data/received.json";
import ActionButton from "../../../../Componets/Buttons/action";
import Status from "../../../../Componets/Status";
import branches from "../../../../Data/branch.json";
import toast from "react-hot-toast";

const InventoryStockTable = () => {
  let header = headers;
  let head = tables;

  const [body, setBody] = useState();

  const get_branch = (id) => {
    let branch = "";
    branches?.map((value, index) => {
      if (id == value?.id) {
        branch = value?.name;
      }
    });

    return branch;
  };

  const fetchData = async () => {
    try {
      let data = [];

      received?.map((value, index) => {
        data?.push({
          Date: value?.date,
          Batch: value?.batch,
          Branch: get_branch(value?.branch),
          Stock: value?.stock,
          Status: <Status status={value?.status} />,
          Action: <ActionButton edit="/app/inventory/stock/add" delete="/" />,
        });
      });

      setBody(data);
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  const formdata = async (data) => {
    console.log(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={header} formdata={formdata}>
      <Table head={head} body={body} />
    </Header>
  );
};

export default InventoryStockTable;
