import React from "react";
import { useNavigate } from "react-router-dom";
import AddButton from "../../Componets/Buttons/add";
import DotIcon from "../../Assets/Svg/dot";
import { FormOutlined } from "@ant-design/icons";
import AreaChart from "../../Componets/Graph/areachart";
import Barchart from "../../Componets/Graph/barchart";

const Dashboard = () => {
  let navigate = useNavigate();

  let projects = [
    {
      number: "#PNO1001",
      name: "Evols Office Remodel",
      cost: "3550",
      revenue: "550",
    },
    {
      number: "#PNO1002",
      name: "Jacks Home Interiors",
      cost: "3550",
      revenue: "550",
    },
    {
      number: "#PNO1002",
      name: "Jacks Home Interiors",
      cost: "3550",
      revenue: "550",
    },
    {
      number: "#PNO1002",
      name: "Jacks Home Interiors",
      cost: "3550",
      revenue: "550",
    },
    {
      number: "#PNO1002",
      name: "Jacks Home Interiors",
      cost: "3550",
      revenue: "550",
    },
  ];

  let suppliers = [
    {
      number: "(968) 76543456",
      name: "PulseTech Innovations",
      cost: "3540",
      revenue: "550",
    },
    {
      number: "(968) 98123456",
      name: "CelestialTech Solutions",
      cost: "2453",
      revenue: "550",
    },
    {
      number: "(968) 78342312",
      name: "AetherDyne Dynamics",
      cost: "1245",
      revenue: "550",
    },
    {
      number: "(968) 23456789",
      name: "NovaGenix Labs",
      cost: "990",
      revenue: "550",
    },
    {
      number: "(968) 23456789",
      name: "ZenithTech Corporation",
      cost: "450",
      revenue: "550",
    },
  ];
  return (
    <div>
      {/* Header Topbar Start */}
      <div className="p-4 bg-white border-b h-[60px] flex justify-between items-center">
        <div className="">
          <span className="">Dashboard</span>
        </div>
        <div></div>
      </div>
      {/* Header Topbar End*/}
      <div className="px-4 py-6">
        <div className="flex justify-between">
          <div className="w-[20%]">
            <div className="border border-green-500 bg-green-500 text-medium rounded-md p-4">
              <div className="text-green-100 text-xl">Revenue</div>
              <div className="text-white text-2xl py-2">
                <div className="flex">
                  <div>0</div>
                  <div className="my-auto ml-2 text-xs rounded-lg bg-green-200 p-1 text-green-500">
                    +0.000%
                  </div>
                </div>
              </div>
              <div className="text-green-100 text-base text-lg">
                +0.000 as of now
              </div>
            </div>
          </div>
          <div className="w-[20%]">
            <div className="border border-red-500 bg-red-500 text-medium rounded-md p-4">
              <div className="text-red-100 text-xl">Expenses</div>
              <div className="text-white text-2xl py-2">
                <div className="flex">
                  <div>0</div>
                  <div className="my-auto ml-2 text-xs rounded-lg bg-red-200 p-1 text-red-500">
                    -0.000%
                  </div>
                </div>
              </div>
              <div className="text-red-100"> -0.000 as of now</div>
            </div>
          </div>
          <div className="w-[20%]">
            <div className="border border-indigo-500 bg-indigo-500 text-medium rounded-md p-4">
              <div className="text-indigo-100 text-xl">Projects</div>
              <div className="text-white text-2xl py-2">
                <div className="flex">
                  <div>0</div>
                  <div className="my-auto ml-2 text-xs rounded-lg bg-indigo-200 py-1 px-2 text-indigo-500">
                    -0.000%
                  </div>
                </div>
              </div>
              <div className="text-indigo-100 text-base text-lg">
                +0 as of now
              </div>
            </div>
          </div>
          <div className="w-[20%]">
            <div className="border border-yellow-500 bg-yellow-500 text-medium rounded-md p-4">
              <div className="text-yellow-100 text-xl">Inventory</div>
              <div className="text-white text-2xl py-2">
                <div className="flex">
                  <div>0</div>
                  <div className="my-auto ml-2 text-xs rounded-lg bg-yellow-200 p-1 text-yellow-500">
                    -0.000%
                  </div>
                </div>
              </div>
              <div className="text-yellow-100">+0 as of now</div>
            </div>
          </div>
        </div>
      </div>
      {/* graph */}
      <div className="flex p-4">
        <div className="w-[50%] bg-white rounded-md p-4">
          <AreaChart />
        </div>
        <div className="ml-4 w-[50%] bg-white rounded-md p-4">
          <Barchart />
        </div>
      </div>

      {/* table */}
      <div className="p-4">
        <div className="flex">
          <div className="bg-white p-4 rounded-md w-[100%]">
            <div className="flex justify-between">
              <div className="text-medium text-lg pb-6">Recent Projects</div>
              <div className="text-medium text-sm pb-6">
                <span
                  onClick={() => navigate("/app/projects")}
                  className="bg-green-500 text-regular  text-sm cursor-pointer hover:bg-green-700 rounded-md p-1 px-3"
                >
                  View
                </span>
              </div>
            </div>
            <table className="w-full ">
              <thead className="text-gray-500 border-b">
                <tr>
                  <td className="pb-4">Number</td>
                  <td>Name</td>
                  <td>Cost</td>
                  <td className="text-right">Revenue</td>
                </tr>
              </thead>
              <tbody>
                {/* {projects?.map((value, index) => {
                  return (
                    <tr
                      className="hover:bg-gray-200"
                      onClick={() => navigate("/app/projects/view")}
                    >
                      <td className="py-3">{value?.number}</td>
                      <td>{value?.name}</td>
                      <td>{value?.cost}</td>
                      <td className="text-right">{value?.revenue}</td>
                    </tr>
                  );
                })} */}
              </tbody>
            </table>
          </div>

          <div className="bg-white ml-4 p-4 rounded-md w-[100%]">
            <div className="flex justify-between">
              <div className="text-medium text-lg pb-6">Top Suppliers</div>
              <div className="text-medium text-sm pb-6">
                <span
                  onClick={() => navigate("/app/suppliers")}
                  className="bg-green-500 text-medium  text-sm cursor-pointer hover:bg-green-700 rounded-md p-1 px-3"
                >
                  View
                </span>
              </div>
            </div>
            <table className="w-full ">
              <thead className="text-gray-500 border-b">
                <tr>
                  <td className="pb-4">Name</td>
                  <td>Phone</td>
                  <td>Paid</td>
                  <td className="text-right">Amount</td>
                </tr>
              </thead>
              <tbody>
                {/* {suppliers?.map((value, index) => {
                  return (
                    <tr
                      className="hover:bg-gray-200"
                      onClick={() => navigate("/app/suppliers/add")}
                    >
                      <td className="py-3">{value?.name}</td>
                      <td>{value?.number}</td>
                      <td>{value?.revenue}</td>
                      <td className="text-right">{value?.cost}</td>
                    </tr>
                  );
                })} */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
