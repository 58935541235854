import { Input, Select } from "antd";
import React, { createRef, useState } from "react";
import AddButton from "../../../../Componets/Buttons/add";
import { select_search } from "../../../../Controllers/Global";
import toast from "react-hot-toast";
import { DeleteOutlined } from "@ant-design/icons";
import Error from "../../../../Componets/Error";
import { get_product_unit } from "../../../../Controllers/Products/Units";

const Productunittable = React.forwardRef((props, focus) => {
  const { details, setDetails } = props;

  const handleSearch = async (e, index) => {
    let data = [...details];

    const select = {
      api: "get-all-product-units",
      search: e,
      status: 1,
    };

    const search = await select_search(select);

    if (search?.status) {
      let options = [];
      search?.data?.map((value, index) => {
        options?.push({ value: value?._id, label: value?.name });
      });

      data[index].options = options;

      setDetails(data);
    } else {
      toast?.error(search?.message);
    }
  };

  const handleName = async (e, index) => {
    let data = [...details];

    if (e) {
      let ids = [];

      data?.map((v, i) => {
        ids?.push(v?.name);
      });

      if (!ids?.includes(e)) {
        let params = {
          id: e,
        };

        const product_unit = await get_product_unit(params);

        console.log(product_unit);

        data[index].name = product_unit?.data?._id;
        data[index].label = product_unit?.data?.name;
        setDetails(data);
      } else {
        data[index].name = null;
        data[index].label = "";
        setDetails(data);
        toast?.error("Unit exists");
      }
    } else {
      data[index].name = null;
      data[index].label = "";
      data[index].conversion = "";
      setDetails(data);
    }
  };

  const handleConversion = (e, index) => {
    if (e?.target?.value >= 0) {
      let data = [...details];

      data[index][e?.target?.name] = e?.target?.value;

      setDetails(data);
    }
  };

  const handleRemove = (index) => {
    let data = [...details];

    data?.splice(index, 1);

    setDetails(data);
  };

  const handleAdd = () => {
    setDetails((prevDetails) => [
      ...prevDetails,
      {
        id: "",
        name: null,
        label: "",
        options: "",
        conversion: "",
      },
    ]);
  };

  return (
    <div>
      <table className="w-full">
        <thead>
          <tr>
            <td className="border p-4 text-black">Name</td>
            <td className="border p-4 text-black">Conversion</td>
            <td className="border p-4 text-black">Remove</td>
          </tr>
        </thead>
        <tbody>
          {details?.map((value, index) => {
            focus.current[`name${index}`] = createRef();
            focus.current[`conversion${index}`] = createRef();
            return (
              <React.Fragment>
                <tr>
                  <td className="border p-4 text-left">
                    {value?.name ? (
                      <div>
                        <div> {value?.label}</div>
                        <Error id={`name${index}`} />
                      </div>
                    ) : (
                      <div>
                        <Select
                          key={index}
                          name="name"
                          ref={focus.current[`name${index}`]}
                          className="w-full text-regular"
                          placeholder="Name"
                          allowClear={true}
                          showSearch={true}
                          filterOption={false}
                          options={value?.options}
                          value={value?.name}
                          onSearch={(e) => handleSearch(e, index)}
                          onClick={(e) => handleSearch("", index)}
                          onChange={(e) => handleName(e, index)}
                          autoComplete="off"
                        />
                        <Error id={`name${index}`} />
                      </div>
                    )}
                  </td>
                  <td className="border p-4">
                    <Input
                      key={index}
                      name="conversion"
                      ref={focus.current[`conversion${index}`]}
                      type="number"
                      className="w-full text-regular"
                      placeholder="Conversion"
                      value={value?.conversion}
                      onChange={(e) => handleConversion(e, index)}
                      autoComplete="off"
                    />
                    <Error id={`conversion${index}`} />
                  </td>
                  <td className="border p-4 text-center">
                    <button
                      type="button"
                      className="text-lg text-red-500 cursor-pointer hover:text-red-800"
                      onClick={() => handleRemove(index)}
                    >
                      <DeleteOutlined />
                    </button>
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <div className="flex justify-end p-3">
        <AddButton onClick={handleAdd} />
      </div>
    </div>
  );
});

export default Productunittable;
