import React, { useEffect, useState } from "react";
import Header from "../../../../Componets/Header";
import Status from "../../../../Componets/Status";
import ActionButton from "../../../../Componets/Buttons/action";
import Table from "../../../../Componets/Table";
import toast from "react-hot-toast";
import tables from "../../../../Data/Tables/Suppliers/Suppliers/tables";
import headers from "../../../../Data/Tables/Suppliers/Suppliers/headers";
import Loadercomponent from "../../../../Componets/Loader";
import { get_all_suppliers } from "../../../../Controllers/Suppliers";

const SuppliersTable = () => {
  const [loader, setLoader] = useState(false);
  const [body, setBody] = useState();

  const formdata = (data) => {
    fetchData(data);
  };

  const actiondata = (data) => {
    fetchData(data);
  };

  const fetchData = async (tabledata) => {
    try {
      setLoader(true);

      const params = {
        search: tabledata?.search,
        sort: tabledata?.sort,
        catalog: tabledata?.catalog,
        status: tabledata?.status,
        type: 0,
      };

      const customers = await get_all_suppliers(params);

      if (customers?.status) {
        let data = [];

        customers?.data?.map((value, index) => {
          data?.push({
            Name: value?.name,
            Email: value?.email,
            Phone: value?.phone,
            Status: <Status status={value?.status} />,
            Action: (
              <ActionButton
                edit={`/app/suppliers/edit/${value?._id}`}
                id={value?._id}
                api="delete-supplier"
                actiondata={actiondata}
              />
            ),
          });
        });

        setBody(data);
        setLoader(false);
      } else {
        toast?.error(customers?.message);
        setLoader(false);
      }
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers} formdata={formdata}>
      <Loadercomponent loader={loader}>
        <Table head={tables} body={body} />
      </Loadercomponent>
    </Header>
  );
};

export default SuppliersTable;
